<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import type { Branch, FirmData } from "@planetadeleste/vue-mc-gw";
import FirmForm from "@/modules/companies/components/FirmForm.vue";
import FirmDgiForm from "@/modules/companies/components/FirmDgiForm.vue";
import CodeFieldText from "@/components/form/fields/CodeFieldText.vue";
import { isEmpty, isNil, set } from "lodash";
import { toCode } from "@/plugins/helpers";
import FormFieldCountry from "@/components/form/fields/Country.vue";
import FormFieldTown from "@/components/form/fields/Town.vue";
import FormFieldState from "@/components/form/fields/State.vue";
import { LocationModule } from "@/store/location";
import FirmFieldState from "@/components/form/fields/FirmFieldState.vue";
import FirmFieldTown from "@/components/form/fields/FirmFieldTown.vue";
import FirmFieldAddress from "@/components/form/fields/FirmFieldAddress.vue";

@Component({
  components: {
    FirmFieldAddress,
    FirmFieldTown,
    FirmFieldState,
    FormFieldState,
    FormFieldTown,
    FormFieldCountry,
    CodeFieldText,
    FirmDgiForm,
    FirmForm,
  },
})
export default class SimpleFields extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obBranch!: Branch;

  get obFirm(): Partial<FirmData> {
    return this.obBranch.firm || {};
  }

  set obFirm(obData: Partial<FirmData>) {
    this.obBranch.set("firm", obData);
  }

  get states() {
    return LocationModule.states.size() > 0;
  }

  get towns() {
    return LocationModule.towns.size() > 0;
  }

  onEmit(obData: Partial<FirmData>) {
    this.obBranch.set("firm", obData);
  }

  onBlurName() {
    if (isNil(this.obFirm.name)) {
      return;
    }

    if (!this.obFirm.code) {
      set(this.obFirm, "code", toCode(this.obFirm.name));
    }

    if (!this.obFirm.dgi_denominacion) {
      set(this.obFirm, "dgi_denominacion", this.obFirm.name);
    }

    this.onEmit(this.obFirm);
  }
}
</script>

<template>
  <v-row>
    <v-col cols="12" lg="6" xl="4">
      <code-field-text v-model="obFirm.code" label="code" required />
    </v-col>

    <v-col cols="12" lg="6" xl="8">
      <form-field-text
        v-model="obFirm.name"
        label="name"
        required
        @blur="onBlurName"
      />
    </v-col>

    <v-col cols="12">
      <firm-field-address :firm="obFirm" />
    </v-col>

    <v-col cols="12" lg="4" md="6">
      <form-field-country v-model="obFirm.country_id" />
    </v-col>

    <v-col cols="12" lg="4">
      <firm-field-state :firm="obFirm" />
    </v-col>

    <v-col cols="12" lg="4">
      <firm-field-town :firm="obFirm" />
    </v-col>

    <v-col cols="12" lg="4">
      <form-field-text v-model="obFirm.email" label="email" />
    </v-col>

    <v-col cols="12" lg="4">
      <form-field-text v-model="obFirm.phone" label="phone" />
    </v-col>

    <v-col cols="12" lg="4">
      <form-field-text v-model="obFirm.mobile" label="mobile" />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="obFirm.description"
        :label="$t('remarks')"
        outlined
      />
    </v-col>
  </v-row>
</template>
